import axios from "axios";
import queryString from "query-string";
const axiosClient = axios.create({
  baseURL: "/",
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // eslint-disable-next-line no-undef
  const tokenKey = location.pathname.includes("admin")
    ? "admin:accessToken"
    : "line:accessToken";
  const token =
    localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if ("data" in response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

export default axiosClient;
