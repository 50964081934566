import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Store from "./../store";
import LineApp from "./LineApp";
import "./style.scss";

const App = () => {
  return (
    <Store>
      <Router history={useHistory()}>
        <LineApp />
      </Router>
    </Store>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement("div"))
  );
});
