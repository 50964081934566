const Reducer = (state, action) => {
  const { type, ...rest } = action;
  // console.log("APP STATE CHANGE", rest);
  switch (type) {
    case "ON_CHANGE":
      return {
        ...state,
        ...rest,
      };
    default:
      return state;
  }
};

export default Reducer;
