import { useContext, useCallback, useMemo } from "react";
import { Context } from "./index";

const store = () => {
  const [state, dispatch] = useContext(Context);

  const getState = { ...state };

  const setAppState = useCallback(
    (object) => {
      dispatch({
        type: "ON_CHANGE",
        ...object,
      });
    },
    [dispatch]
  );
  return useMemo(
    () => ({
      ...state,
      dispatch,
      setAppState,
      getState,
    }),
    [JSON.stringify(state)]
  );
};

export default store;
