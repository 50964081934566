import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";
import PropTypes from "prop-types";
const tokenKey = location.pathname.includes("admin")
  ? "admin:accessToken"
  : "line:accessToken";
const token =
  localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

const initState = {
  isAuthenticated: !!token,
  isAndroid: navigator.userAgent.includes("Android"),
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initState);
  if (process.env.NODE_ENV !== "production") {
    console.log("%cAPP STATE", "color: green", state);
  }

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initState);

Store.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Store;
