import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Redirect, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import store from "@store";
import "./style.scss";

const Login = () => {
  const { isAuthenticated } = store();
  const location = useLocation();

  useEffect(() => {
    console.log("useLocation", location);
    const params = new URLSearchParams(location.search);
    const next = params.get("next");
    if (next) {
      localStorage.setItem("next", next);
    }
  }, []);

  const nextUrl = localStorage.getItem("next");

  if (isAuthenticated)
    return <Redirect to={{ pathname: nextUrl ? nextUrl : "/line" }} />;

  return (
    <Container>
      <Header />
      <div className="login-wrapper">
        <div className="login-content text-center">
          <div className="login-title">LINE ログイン</div>
          <a className="login-btn d-block align-self-center" href="/auth/line">
            <img src="/images/line/icons/line-messenger.svg" className="mr-3" />
            LINEログイン
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Login;
