import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div
      className="position-fixed d-flex align-items-center justify-content-center"
      style={{ width: "100vw", height: "100vh", top: 0, left: 0 }}
    >
      <Spinner animation="border" />
    </div>
  );
};

export default Loading;
